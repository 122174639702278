import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import {
  mdiClipboardAccount,
  mdiBookEducation,
  mdiBriefcaseEdit,
} from "@mdi/js";

import "../CSS/Resumen.css";
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';
import Preload from './Preload';


const Resumen = () => {
  const [empleos, setData] = useState(null);
  const [educacion, setEducacion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(apiUrl+"jobs")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.log(error));

    axios
        .get(apiUrl+"studies")
        .then((response) => {
            setEducacion(response.data);
            setIsLoading(false);
        })
        .catch((error) => console.log(error));

  }, []);

  function formatDate(startDate, endDate) {
    let start = moment(startDate, 'YYYY-MM-DD').locale('es');
    let end = moment(endDate, 'YYYY-MM-DD').locale('es');
    if (!end.isValid()) {
      end = "Actualidad";
    } else {
      end = end.format('D [de] MMMM [de] YYYY');
    }
    start = start.format('D [de] MMMM [de] YYYY');
    return `${start} - ${end}`;
  }
  function formatDateOne  (startDate) {
    let start = moment(startDate, 'YYYY-MM-DD').locale('es');
    start = start.format('D [de] MMMM [de] YYYY');
    return `${start}`;
}
  function dateValidate(date) {
    let end = moment(date, 'YYYY-MM-DD').locale('es');
    let active ='';
    if (!end.isValid()) {
      active = "active";
    }
    return active;
  }
  const Path_Logos_Educacion = require.context("../Images/educacion", true);
  const Path_Logos_Trabajo = require.context("../Images/trabajo", true);
 
  if (educacion) {
    educacion.map((item, index) => {
      //realizamo try cash
        try {
          item.photo = Path_Logos_Educacion(`./${item.photo}`);
        } catch (error) {
        }
    });
  }
  if (empleos) {
    empleos.map((item, index) => {
      try{
        item.photo = Path_Logos_Trabajo(`./${item.photo}`);
      }catch(error){}
    });
  }
  if (isLoading) {
    return (
      <Preload></Preload>
    );
}
  return (
    <div id="experience" className="content_box">
      <h1 className="content_box_title">
        <Icon path={mdiClipboardAccount} className="Main_Icons" size={1} />
        Resumen
      </h1>
      <div className="content_box_resumen">
        <div className="column">
          <h1 className="content_box_title">
            <Icon path={mdiBriefcaseEdit} className="Main_Icons" size={1} />
            Experiencia
          </h1>
          <ul className="content_box_resumen_list">
            {empleos &&
              empleos.map((item, index) => (
                item.charge !== 'camilo' ? (
                <li className="content_box_resumen_list_item" key={index}>
                  <ul className={dateValidate(item.dateEnd)} >
                    <li><span>Empresa:</span> {item.enterprise}</li>
                    <li><span>Duración:</span> {formatDate(item.dateEntry,item.dateEnd)}</li>
                    <li><span>Cargo:</span> {item.charge}</li>
                    <li><span>Trabajo:</span> {item.work}</li>
                  </ul>
                  <div className="content_box_resumen_icon_div">
                    <img className="content_box_resumen_icon" src={item.photo} />
                  </div>
                </li>
                ) : null
              ))}
          </ul>
        </div>
        <div className="column">
          <h1 className="content_box_title">
            <Icon path={mdiBookEducation} className="Main_Icons" size={1} />
            Estudios
          </h1>
          <ul className="content_box_resumen_list">
          {educacion &&
              educacion.map((item, index) => (
              <li className="content_box_resumen_list_item" key={index}>
                <ul className={dateValidate(item.dateEnd)}>
                  <li>{formatDateOne(item.dateEnd)}</li>
                  <li><span>{item.institution}</span></li>
                  <li>{item.title}</li>
                  <li>{item.direction}</li>
                </ul>
                <div className="content_box_resumen_icon_div">
                  <img className="content_box_resumen_icon" src={item.photo} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resumen;
