
import '../CSS/Background.css';
import React, { useState,useEffect } from 'react';
import Icon  from '@mdi/react';
import Preload from './Preload';
import { mdiClipboardAccount,mdiCalendarRange,mdiBriefcaseOutline,mdiMapMarker,mdiMapMarkerRadius,mdiCodeBraces,mdiPencilRuler,mdiFaceAgent,mdiAccountGroup} from '@mdi/js';

import parse from 'html-react-parser';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from 'axios';



function Acerca_de_mi() {
        const [about, setAbout] = useState({about:""});
        const [isLoading, setIsLoading] = useState(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        useEffect(() => {
            axios
            .get(apiUrl+"about")
            .then((response) => {
                console.log(response.data);
                setAbout(response.data[0]);
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
        }, []);
        const Path_Carrusel = require.context('../Images', true);
        const Images_Carrusel = [
            './client_1.png',
            './client_2.png',
            './client_3.png',
            './client_4.png'
        ];
        var Images_src = [];
        Images_Carrusel.map((item, index) => {
            Images_src.push(Path_Carrusel(`${item}`));
        });
        function getYearBirthday(birthday) {
            // Obtener fecha actual
            const currentDate = new Date();
            // Convertir fecha de nacimiento en formato de fecha
            const birthDate = new Date(birthday);
            // Calcular edad
            let age = currentDate.getFullYear() - birthDate.getFullYear();
            // Restar un año si aún no se ha cumplido el cumpleaños
            const month = currentDate.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && currentDate.getDate() < birthDate.getDate())) {
                age--;
            }
            // Devolver edad
            return age;
        }        
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1
        };
        var currentTime = new Date();
        var year = currentTime.getFullYear();
        year = year - 1986;
        if (isLoading) {
            return (
                <Preload></Preload>
            );
        }
        return (
            <div id="about_me" className="content_box">
                <h1 className="content_box_title">
                    <Icon path={mdiClipboardAccount} className="Main_Icons" size={1} />
                    Acerca de mi
                </h1>
                <div className='box_p'>{about && parse(about.about)}</div>
                
                <ul className="content_box_list">
                    <li>

                        <Icon path={mdiCalendarRange} className="Main_Icons" size={1} />
                        <span>
                            <b>Años:</b> {getYearBirthday(about.birthdate)}
                        </span>
                       
                    </li>
                    <li>
                        <Icon path={mdiBriefcaseOutline} className="Main_Icons" size={1} />
                        <span><b>{about.location}</b></span>
                    </li>
                    <li>
                        <Icon path={mdiMapMarker} className="Main_Icons" size={1} />
                        
                        <span><b>Residente:</b> {about.country}</span>
                    </li>
                    <li>
                        <Icon path={mdiMapMarkerRadius} className="Main_Icons" size={1} />
                        <p><b>Dirección:</b> {about.city}</p>
                    </li>
                </ul>
                <h1 className="content_box_title">
                    <Icon path={mdiFaceAgent} className="Main_Icons" size={1} />
                    <span><b>Mis servicios</b></span>
                </h1>
                <ul className="content_box_list">
                    <li className="content_box_sub_title">
                        <Icon path={mdiPencilRuler} className="Main_Icons" size={1} />
                        <span><b>DISEÑO DE PÁGINAS WEB</b></span>
                    </li>
                    <li className="content_box_text">Un diseño actual, limpio y estructurado te ayudará a conseguir nuevos clientes en internet.</li>
                    <li className="content_box_sub_title">
                        <Icon path={mdiCodeBraces} className="Main_Icons" size={1} />
                        <span><b>PROGRAMACIÓN WEB A MEDIDA</b></span>
                    </li>
                    <li className="content_box_text">Desarrollo a medida de cualquier herramienta online que necesites tener en tu página web.</li>
                </ul>
                <h1 className="content_box_title">
                    <Icon path={mdiAccountGroup} className="Main_Icons" size={1} />
                    <span><b>Clientes</b></span>
                </h1>
                <div className="content_box_carrusel">
                    <Slider {...settings}>
                        {
                            Images_src.map((src,index) => (
                                <div key="div-{index}" >
                                    <img className="content_box_carrusel_img" key="img-{index}" src={src} />
                                </div>
                            ))
                        }
                    </Slider>
                </div>    
                
            </div>
        );
}

export default Acerca_de_mi;