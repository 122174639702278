import "../CSS/Portafolio.css";
import "../CSS/Modal.css";
import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiXml } from "@mdi/js";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import parse from "html-react-parser";
import axios from "axios";
import Preload from "./Preload";
const Portafolio = () => {
  const [portafolio, setPortaforlio] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(apiUrl + "portfolio")
      .then((response) => {
        setPortaforlio(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const [open, setOpen] = useState(false);
  const [datamodal, setDataModal] = useState({
    title: "",
    description: "",
    banner: "",
    subtitle: "",
    link: "",
  });
  const onOpenModal = (item) => {
    setDataModal(item);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);
  const Path_Logos_Trabajo = require.context("../Images/trabajos", true);

  portafolio.map((item) => {
    try {
      if (/^https?:\/\//i.test(item.logo)) {
        item.src = item.logo;
      } else {
        item.src = Path_Logos_Trabajo(`./${item.logo}`);
      }
    } catch (error) {
      item.src = Path_Logos_Trabajo(`./logo_work1.svg`);
    }
    try {
      if (/^https?:\/\//i.test(item.photo)) {
        item.banner = item.photo;
      } else {
        item.banner = Path_Logos_Trabajo(`./${item.photo}`);
      }
    } catch (error) {
      item.banner = Path_Logos_Trabajo(`./logo_work1.svg`);
    }

    // Check if the link has http or https, if not, add http
    if (!/^https?:\/\//i.test(item.link)) {
      item.link = `${item.link}`;
    }

    return item;
  });
  if (isLoading) {
    return <Preload></Preload>;
  }
  return (
    <div id="potafolio" className="content_box">
      <h1 className="content_box_title">
        <Icon path={mdiXml} className="Main_Icons" size={1} />
        Portafolio
      </h1>
      <div className="grid_portafolio">
        <Modal open={open} onClose={onCloseModal} center>
          <h1 className="title_modal">{datamodal.title}</h1>
          <h2 className="sub_title_modal">{datamodal.subtitle}</h2>
          <div className="content_modal">
            <img className="poster_modal" src={datamodal.banner} alt="" />
            <p className="text_modal">
              {parse(datamodal.description)}
              <a target="_blank" href={datamodal.link} className="btn_modal">
                Ver
              </a>
            </p>
          </div>
        </Modal>
        {portafolio.map((item, index) => (
          <div
            onClick={() => onOpenModal(item)}
            key={index}
            className="item_portafolio"
          >
            <img
              key={item.src}
              className="item_image_portafolio"
              src={item.src}
              alt={item.title}
            />
            <div key={item.title} className="item_cortina">
              <div key={index} className="item_cortina_text">
                {item.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portafolio;
