import avatar from "../Images/avatar.png";
import Scroll from "../Models/Scroll";
import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiGithub } from "@mdi/js";
import { mdiGitlab } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";
import axios from "axios";

import {
  mdiClipboardAccount,
  mdiCodeArray,
  mdiBriefcase,
  mdiViewGridPlus,
  mdiDownload,
} from "@mdi/js";
import "../CSS/Main.css";
function Main() {
  const [about, setAbout] = useState({ about: "" });
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(apiUrl + "about");
  useEffect(() => {
    console.log("useEffect");
    axios
      .get(apiUrl + "about")
      .then((response) => {
        console.log("response.data");
        console.log(response.data[0]);
        console.log(response.data[0]);
        setAbout(response.data[0]);
      })
      .catch((error) => console.log(error));
  }, []);
  Scroll((SPosT) => {
    const root = document.querySelector(":root");
    root.style.setProperty("--progres-scroll-y", SPosT + "%");
  });

  return (
    <div className="Main">
      <div className="Main_Progress_bar"></div>
      <div className="Main_Info_basic">
        <img src={avatar} alt="Main_Avatar" className="Main_Avatar" />
        <div className="Main_Name">Edilson Laverde Molina</div>
        <div className="Main_Rol">Desarrollador Web</div>
      </div>
      <ul className="Main_Dates">
        <li>
          <a target="_blank" href="https://hvapi.app.elaverde.me/getPDF">
            <Icon path={mdiDownload} className="Main_Icons" size={1} />
            Descargar
          </a>
        </li>
        <li>
          <a href="mailto:edilsonlaverde_182@hotmail.com">
            <Icon path={mdiClipboardAccount} className="Main_Icons" size={1} />
            Contacto
          </a>
        </li>
      </ul>
      <ul className="Main_List">
        <li>
          <a href="#about_me">
            <Icon path={mdiClipboardAccount} className="Main_Icons" size={1} />
            <p>Acerca de mi</p>
          </a>
        </li>
        <li>
          <a href="#skills">
            <Icon path={mdiCodeArray} className="Main_Icons" size={1} />
            Skills
          </a>
        </li>
        <li>
          <a href="#experience">
            <Icon path={mdiBriefcase} className="Main_Icons" size={1} />
            Experiencia
          </a>
        </li>
        <li>
          <a href="#potafolio">
            <Icon path={mdiViewGridPlus} className="Main_Icons" size={1} />
            Poratafolio
          </a>
        </li>
      </ul>
      <div className="Main_Social">
        <ul>
          <li>
            <a target="_blank" href={about.github}>
              <Icon path={mdiGithub} size={1} />
              <span> GitHub </span>
            </a>
          </li>
          <li>
            <a target="_blank" href={about.linkedin}>
              <Icon path={mdiLinkedin} size={1} />
              <span> LinkedIn </span>
            </a>
          </li>
          <li>
            <a target="_blank" href={about.gitlab}>
              <Icon path={mdiGitlab} size={1} />
              <span> GitLab </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Main;
