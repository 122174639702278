import Main from "./Componets/Main";
import Background_app from "./Componets/Background_app";
import Acerca_de_mi from "./Componets/Acerca_de_mi";
import Resumen from "./Componets/Resumen";
import Skill from "./Componets/Skill";
import Content from "./Componets/Content";
import Portafolio from "./Componets/Portafolio";
import Buscador from "./Componets/Buscador";
import "./App.css";

import React, { useEffect } from "react";
//google analitics
import ReactGA from "react-ga";
const TRACKING_ID = "UA-137266075-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Main />
      <Content>
        <Acerca_de_mi />
        <Skill />
        <Resumen />
        <Portafolio />
        <Buscador />
      </Content>
      <Background_app />
    </>
  );
}

export default App;
