import '../CSS/Skill.css';
import React, { useState, useEffect } from "react";
import Icon from '@mdi/react';
import {mdiXml } from '@mdi/js';
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import Preload from './Preload';

function Skill() {
    const [skills, setSkill] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        axios
            .get(apiUrl+"skills")
            .then((response) => {
            
                setSkill(response.data);
                setIsLoading(false);
            })
      .catch((error) => console.log(error));
    },[]);
    if (isLoading) {
        return (
            <Preload></Preload>
        );
    }
    return (
        <div id="skills" className="content_box">
            <h1 className="content_box_title">
                <Icon path={mdiXml} className="Main_Icons" size={1} />
                Skills
            </h1>
            <div className="Skills">
                <ul className="Skill_List">
                    {skills.map(skill => (
                        <li key={skill.id}>
                            <div>
                            <label className="Skill_Label">
                            <i className={skill.icon}></i>
                            {skill.name}
                            </label>
                            <ProgressBar
                                completed={skill.percentage}
                                bgColor="#20CE65"
                                height="6px"
                                borderRadius="3px"
                                labelAlignment="center"
                                baseBgColor="#19232B"
                                labelSize="0"
                                margin="0"
                                padding="0"
                                transitionDuration="3s"
                                transitionTimingFunction="ease"
                                animateOnRender
                                maxCompleted={100}
                            />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
export default Skill;