import React, { useState, useEffect } from "react";
import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import parse from "html-react-parser";
import axios from "axios";
import "../CSS/Buscador.css";

const Buscador = () => {
  const MAX_VISIBLE_ITEMS = 3;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [portafolio, setPortaforlio] = useState([]);
  const [visibleItems, setVisibleItems] = useState(MAX_VISIBLE_ITEMS);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    axios
      .get(apiUrl + "portfolio/search?query=" + searchTerm)
      .then((response) => {
        setPortaforlio(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + MAX_VISIBLE_ITEMS);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Actualiza el término de búsqueda
  };

  // Filtrar los elementos del portafolio según el término de búsqueda
  const filteredPortafolio = portafolio.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div id="buscador" className="content_box" style={{ color: "white" }}>
      <h1 className="content_box_title">
        <Icon path={mdiMagnify} className="Main_Icons" size={1} />
        Buscar Recursos
      </h1>
      <div className="content_box_buscador">
        <input
          type="text"
          placeholder="Buscar..."
          className="content_box_buscador_input"
          value={searchTerm}
          onChange={handleSearchChange} // Manejar cambios en el input
        />
        <button className="content_box_buscador_button">
          <Icon path={mdiMagnify} className="Main_Icons" size={1} />
        </button>
      </div>
      <div className="content_box_results">
        {filteredPortafolio.length > 0 ? (
          filteredPortafolio.slice(0, visibleItems).map((item, index) => (
            <a
              href={item.link} // Enlace dinámico
              target="_blank" // Abrir en una nueva pestaña
              rel="noopener noreferrer" // Seguridad
              className="Card"
              key={index}
            >
              <div className="Card-Image">
                <img src={item.photo} alt={item.title} />
              </div>
              <div className="Card-text">
                <h3>{item.title}</h3>
                <p>{parse(item.description)}</p>
              </div>
              <div className="Card-footer">
                <p>{item.subtitle}</p>
              </div>
            </a>
          ))
        ) : (
          <div className="no-results-message">No se encontraron resultados</div>
        )}
      </div>
      {visibleItems < filteredPortafolio.length && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Cargar más
        </button>
      )}
    </div>
  );
};

export default Buscador;
