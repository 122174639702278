


function Preload() {
    return (
        <div className="content_box">
            <h1 className="content_box_title">Cargando...</h1>
        </div>
    );
}

export default Preload;